import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import SearchForm from '../components/SearchForm';
import Table from 'react-bootstrap/Table';
import Axios from "axios/index";
import Pagination from 'react-bootstrap/Pagination';

const SigningCompleted = () => {

    /**
     * Controlla se l'utente è loggato, se non è loggato
     * viene reindirizzato alla pagina di login
     */
    let access_token = Cookies.get('access_token');
    const [data, setData] = useState([]);
    const [responseEp, setResponseEp] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentPagination, setCurrentPagination] = useState(1);
    //const [rateoPagination, setRateoPagination] = useState(5);
    let rateoPagination = 10;
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(rateoPagination);
    const fData = new FormData();

    let endpoint = process.env.REACT_APP_ENPOINT + "is-user-logged";

    const isUserLogged = async () => {
        //e.preventDefault();
        const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Authorization' :   'Bearer ' + access_token,
            'Accept'        :   'application/json',
            'Content-Type'  :   'application/json', 
        }
        });
        const result = await response.json();

        //result === 'auth' ? navigate('/signing-pending') : null;
        if ( result === 'auth' ) {
            console.log('navigation');
            setIsLoggedIn(true);
        }
        return result;
    };

    useEffect(()=>{
        isUserLogged();
    }, []);

    if ( isLoggedIn ) {

        const getListCompleted = async () => {

            if ( !responseEp ) {
                console.log('is logged completed signing route');
                let endpoint = process.env.REACT_APP_ENPOINT + "signing-completed";
                //fetch(endpoint).then(response => response.json()).then(data => console.log(data));
                const response = await fetch(endpoint, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization' :   'Bearer ' + access_token,
                        'Accept'        :   'application/json',
                        'Content-Type'  :   'application/json',
                    }
                });
                const result = await response.json();
                setData(result);
                setResponseEp(true);
                console.log(result);
            }
        };
        getListCompleted();
    }


    const lista = data.map((numero, index) =>{

        const date = new Date(numero.created_at);
        const timestamp = date.getTime();
        const date2 = new Date(timestamp);
        const dateLocale = date2.toLocaleString('it-IT');

        return  <tr key={index}>
                    <td>{numero.id}</td>
                    <td><a href={ "/paperwork/" + numero.id }>{numero.paperwork_name}</a></td>
                    <td>{dateLocale}</td>
                    <td>{numero.department_name}</td>
                    <td><b>{ (numero.status === 'DELETED') ? 'deleted' : 'completed' }</b></td>
                </tr>
    });

    const handlePagination = pagination => () => {
        
        console.log(pagination);
        if (pagination === 0 ) {
            console.log('pag');
            setCurrentPagination(pagination + 1);
        } else {
            setCurrentPagination(pagination);
        }

        
        if (pagination > data.length/rateoPagination ) {
            setCurrentPagination(Math.ceil(data.length/rateoPagination));
            setEndIndex(data.length);
            setStartIndex(data.length - rateoPagination);
        }

        if (pagination < 1 ) {
            setCurrentPagination(1);
            setEndIndex(pagination * rateoPagination);
            setStartIndex(0);
        }

        if ( 1 < pagination < data.length/rateoPagination ) {
            setEndIndex(pagination * rateoPagination);
            setStartIndex(pagination * rateoPagination - rateoPagination);
        }
    }

    let items = [];
    //const paginationRender = current => () => {

    let active = 1;
    let maxNumberPagination = Math.floor(data.length / rateoPagination) + data.length % rateoPagination ;

    items.push(<Pagination.First onClick={handlePagination(1)}/>);
    if ( currentPagination == 1 ) {
        items.push(<Pagination.Prev disabled onClick={handlePagination(currentPagination - 1)}></Pagination.Prev>);
    } else {
        items.push(<Pagination.Prev onClick={handlePagination(currentPagination - 1)}></Pagination.Prev>);
    }

    items.push(<Pagination.Item active={currentPagination}>{currentPagination}</Pagination.Item>);
    items.push(<Pagination.Ellipsis></Pagination.Ellipsis>);

    for (let number = 1; number <= maxNumberPagination; number++) {    

        if ( number - 1 > 0 && (number <= currentPagination + 5 ) && (number > currentPagination + 1 )) {
            items.push(
                <Pagination.Item key={number} active={number === active} onClick={handlePagination(number)}>
                    {number}
                </Pagination.Item>,
                );
        }

        if ( number === maxNumberPagination ) {
            
        }
    }

    items.push(<Pagination.Ellipsis></Pagination.Ellipsis>);
    items.push(<Pagination.Item>{maxNumberPagination}</Pagination.Item>);
    if ( Math.ceil(data.length / rateoPagination) === currentPagination ) {
        items.push(<Pagination.Next disabled onClick={handlePagination(currentPagination + 1)}/>);
    } else {
        items.push(<Pagination.Next onClick={handlePagination(currentPagination + 1)}/>);
    }

    items.push(<Pagination.Last onClick={handlePagination(data.length)}/>);

    return (
        <Container>
            <Col>
                <div id="wrapper-content" className={"container-content"}>
                    <div>
                        <h1>Archive</h1>
                        <SearchForm/>
                        <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Operation name</th>
                                <th>Creation date</th>
                                <th>Department name</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { (data.length <= rateoPagination) ? lista : lista.slice(startIndex, endIndex)}
                        </tbody>
                        </Table>
                    </div>
                </div>
            </Col>
            <Row>
        <Col>
        <div class="wrapper-content">
            <Pagination disabled>
                {items}
            </Pagination>
            </div>
        </Col>
        </Row>
        </Container>
        
    );
}
export default SigningCompleted;


/**
 * 
 * 
 * 
 */