import React, { useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import Table from 'react-bootstrap/Table';
import Axios from "axios/index";
import { Navigate, useNavigate, useLocation } from 'react-router-dom';

const Paperwork = () => {

    /**
     * Controlla se l'utente è loggato, se non è loggato
     * viene reindirizzato alla pagina di login
     */
    let access_token = Cookies.get('access_token');
    const [data, setData] = useState([]);

    const [paperwork, setPaperwork] = useState([]);
    const [signers, setSigners] = useState([]);
    const [readers, setReaders] = useState([]);
    const [departmentName, setDepartmentName] = useState('');
    const [filesNotSigned, setFilesNotSigned] = useState([]);
    const [filesSigned, setFilesSigned] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [fileDownload, setFileDownload] = useState('');
    const [paperworkId, setPaperworkId] = useState('');

    const [responseEp, setResponseEp] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading]       = useState(false);
    const [statusPaperwork, setStatusPaperwork] = useState(false);
    const [statusTitleTableCol, setStatusTitleTableCol] = useState('');

    //For change data signer
    const [showChangeFirmatario, setShowChangeFirmatario] = useState(false);
    const [changeSigner, setChangeSigner] = useState([]);
    const [messageEmailValidation, setMessageEmailValidation] = useState([]);
    const [disableButtonUpdateDataSigner, setDisableButtonUpdateDataSigner] = useState(false);

    const [colorMessage, setColorMessage] = useState('');
    const [isDeleted, setIsDeleted] = useState(false);
    const [emailCheckValidation, setEmailCheckValidation] = useState('');
    const arrChangeSigner = [];
    const form = useRef(null);


    const fData = new FormData();

    const location = useLocation();

    const downloadFileHandle = file => () => {

        //e.preventDefault();

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };


        fData.append('file_name', file);
        fData.append('paperwork_id', paperworkId);

        /*Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };*/

        let endpoint = process.env.REACT_APP_ENPOINT + "download-file-to-sign";
        Axios({
            method: "post",
            responseType: 'blob', // importante, altrimenti non funziona, pdf mal formattato
            url: endpoint,
            data: fData,
            headers: {
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch( e => {
            console.error('Failure', e);
        });
    }


    const downloadFileSignedHandle = file => () => {

        //e.preventDefault();

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        fData.append('file_name', file);
        fData.append('paperwork_id', paperworkId);

        /*Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };*/

        let endpoint = process.env.REACT_APP_ENPOINT + "download-file-signed";
        Axios({
            method: "post",
            responseType: 'blob', // importante, altrimenti non funziona, pdf mal formattato
            url: endpoint,
            data: fData,
            headers: {
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'document-signed.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch( e => {
            console.error('Failure', e);
        });
    }



    const getListCompleted = async () => {

        if ( !responseEp ) {
            let endpoint = process.env.REACT_APP_ENPOINT + location.pathname.substring(1);
            //fetch(endpoint).then(response => response.json()).then(data => console.log(data));
            const response = await fetch(endpoint, {
                method: 'PUT',
                //body: JSON.stringify(data),
                headers: {
                    'Authorization' :   'Bearer ' + access_token,
                    'Accept'        :   'application/json',
                    'Content-Type'  :   'application/json',
                }
            });
            const result = await response.json();
            setData(result);
            //setResponseEp(true);
            setPaperwork(result.paperwork);
            setSigners(result.signers);
            setReaders(result.readers);
            setPaperworkId(result.paperwork_id);
            setFilesNotSigned(result.files);
            setFilesSigned(result.files_s);
            setDepartmentName(result.department_name);

            if ( result.paperwork[0].completed === 1 ) {
                setStatusTitleTableCol('Data completamento');
                setStatusPaperwork(true);

            }
            if ( result.paperwork[0].completed === 0 ) {
                setStatusTitleTableCol('Data creazione');
                setStatusPaperwork(false);
            }
            return result;
        }
    };

    const handleGoBackChangeFirmatario = () => {
        //setMessageEmailValidation("");
        //setColorMessage('black-message');
        setShowChangeFirmatario(false);
    }

    const handleChangeData = change => () => {
        //console.log(Object.entries(change));


        Object.entries(change).map(([key, value]) => {
            //console.log(key);
            //console.log(value);
        });
        console.log('change');
        console.log(change);

        arrChangeSigner['id_signer'] = change.id;
        arrChangeSigner['firstname'] = change.name.trim();
        arrChangeSigner['lastname'] = change.surname.trim();
        arrChangeSigner['email'] = change.email.trim();
        arrChangeSigner['phone_number'] = change.phone_number.trim();

        //ListSelectPrefix(change.phone_number);

        //const arr = Object.keys(change).map((k) => change[k]);

        const arr = Array.from(Object.keys(change), k => change[k]);
        setChangeSigner(arrChangeSigner);

        setShowChangeFirmatario(true);
        //Soto per rimuovere il firmatario da signers
        //setSigners(prev => prev.filter((el) => el[0].email !== change ));

    };

    const downloadAuditTrail = signer => () => {
        //download-audit-trail-to-signer
        const fData = new FormData();
        setLoading(true);

        for (var key in signer) {


            if ( key === 'name') {
                if ( dataUpdate.firstname != null ) {
                    fData.append('signer_to_update[firstname]', signer.firstname.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[firstname]', signer[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'surname') {
                if ( dataUpdate.lastname != null ) {
                    fData.append('signer_to_update[lastname]', signer.lastname.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[lastname]', signer[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'email') {
                if ( dataUpdate.email != null ) {
                    fData.append('signer_to_update[' + key + ']', signer.email.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', signer[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'phone_number') {
                if ( dataUpdate.phone_number != null ) {
                    fData.append('signer_to_update[' + key + ']', signer.phone_number.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', signer[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

        }

        //fData.append('Signer_to_update[]', {key: dataSigner[key]});

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };

        let obj = {json_data:JSON.stringify(data)};
        let endpoint = process.env.REACT_APP_ENPOINT + "download-audit-trail-to-signer";
        Axios({
            method: "post",
            responseType: 'blob', // importante, altrimenti non funziona, pdf mal formattato
            url: endpoint,
            data: dataUpdate,
            headers: {
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {

            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'audit-trail.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }).catch( e => {
            setLoading(true);
            console.error('Failure', e);
        });
    }

    const handleChangeEmail = input => {

        setEmailCheckValidation(input.value);

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //const pattern = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/i;
        const pattern = /^\s+|\s+$/g;


        input.value.replace(pattern, "");

        if(regex.test(input.value) === true){
            setMessageEmailValidation("Valid email format.");
            setColorMessage('black-message');
        }
        //setPaperwork(input.value);
    };

    const [dataUpdate, setDataUpdate] = useState({id_signer: null, firstname: null, lastname: null, email: null, phone_number: null});

    const subimtDataChange = dataSigner => () => {

        //data.preventDefault();

        console.log(dataSigner);
        console.log(dataUpdate);
        setDisableButtonUpdateDataSigner(true);

        const fData = new FormData();
        setLoading(true);

        for (var key in dataSigner) {

            if ( key === 'id_signer') {
                if ( dataUpdate.id_signer != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.id_signer.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'firstname') {
                if ( dataUpdate.firstname != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.firstname.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'lastname') {
                if ( dataUpdate.lastname != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.lastname.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'email') {
                if ( dataUpdate.email != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.email.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'phone_number') {
                if ( dataUpdate.phone_number != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.phone_number.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

        }

        //fData.append('Signer_to_update[]', {key: dataSigner[key]});

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };

        let obj = {json_data:JSON.stringify(data)};
        let endpoint = process.env.REACT_APP_ENPOINT + "update-signer";

        Axios({
            method: "post",
            url: endpoint,
            data: dataUpdate,
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {

            if (res.data.hasOwnProperty('error')){
                alert(res.data.error);
                setLoading(false);
            } else {
                setLoading(false);
                if ( typeof res.data.message_error != 'undefined' ) {
                    alert(res.data.message_error);
                    setDisableButtonUpdateDataSigner(false);
                } else {
                    alert('Data signer updated. A reminder has just been sent.');
                    setTimeout( function () {
                        setDisableButtonUpdateDataSigner(true);
                        window.location.reload(false);
                    }, 3000);
                }
                //window.location.href = '/signing-pending';
            }
        }).catch( e => {
            setLoading(true);
            console.error('Failure', e);
            setDisableButtonUpdateDataSigner(false);
        });
    };


    const subimtReminder = dataSigner => () => {

        //data.preventDefault();
        const fData = new FormData();
        setLoading(true);



        for (var key in dataSigner) {

            if ( key === 'id') {
                if ( dataUpdate.firstname != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.id.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'firstname') {
                if ( dataUpdate.firstname != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.firstname.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'lastname') {
                if ( dataUpdate.lastname != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.lastname.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'email') {
                if ( dataUpdate.email != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.email.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

            if ( key === 'phone_number') {
                if ( dataUpdate.phone_number != null ) {
                    fData.append('signer_to_update[' + key + ']', dataUpdate.phone_number.trim());
                    fData.append('paperwork_id', paperworkId);
                } else {
                    fData.append('signer_to_update[' + key + ']', dataSigner[key]);
                    fData.append('paperwork_id', paperworkId);
                }
            }

        }

        //fData.append('Signer_to_update[]', {key: dataSigner[key]});

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };

        let obj = {json_data:JSON.stringify(data)};
        let endpoint = process.env.REACT_APP_ENPOINT + "send-reminder-to-signer";
        Axios({
            method: "post",
            url: endpoint,
            data: dataUpdate,
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {

            if (res.data.hasOwnProperty('error')){
                alert(res.data.error);
                setLoading(false);
            } else {
                setLoading(false);
                if ( typeof res.data.message_error != 'undefined' ) {
                    alert(res.data.message_error);
                } else {
                    alert('A reminder has just been sent.');

                }
                //window.location.href = '/signing-pending';
            }
        }).catch( e => {
            setLoading(true);
            console.error('Failure', e);
        });
    };

    useEffect(()=>{
        getListCompleted();

        /*data.forEach( item => {
            console.log(item.completed);
        });*/
    }, []);

    const signersList = signers.map((numero, index) =>
        <tr key={index}>
            <td>{numero.id}</td>
            <td>{numero.name} {numero.surname}</td>
            <td>{numero.email}</td>
            <td>{numero.phone_number}</td>
            <td><b>{numero.signed ? 'completed' : 'pending'}</b></td>
            {paperwork.map((paperwork, index) => {
                //console.log(typeof numero.signed);
                //console.log(numero);
                //if (paperwork.status === 'DELETED' || paperwork.id < 203) {
                if (paperwork.status === 'DELETED' || paperwork.id < 138) {
                    return <td><span className={'colore-red bold-magenta'}><b>Not allowed</b></span></td>
                } else if ( paperwork.status === 'COMPLETED' ) {
                    return numero.signed ? <td><span onClick={downloadAuditTrail(numero)} className={'colore-red cursor'}><b>Download</b></span></td> : <td><span onClick={handleChangeData(numero)} className={'colore-red cursor'}><b>Click to correct</b></span></td>
                } else if ( paperwork.status === 'PENDING' ) {
                    return numero.signed ? <td><span className={'bold-magenta'}><b>Not allowed</b></span></td> : <td><span onClick={handleChangeData(numero)} className={'colore-red cursor'}><b>Edit</b></span></td>
                }
            })}

            {paperwork.map((paperwork, index) => {
                //console.log(numero);
                //if ( paperwork.status === 'PENDING' && paperwork.id >= 203) {
                if ( paperwork.status === 'PENDING' && paperwork.id >= 138) {

                    if ( numero.signed == 1 ) {
                        return             <td><Button variant="primary" className={["d-bgcolor","d-bordercolor"]} disabled onClick={subimtReminder(numero)}>
                            Reminder
                        </Button></td>
                    } else {
                        return             <td><Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={subimtReminder(numero)}>
                            Reminder
                        </Button></td>
                    }

                } else if (paperwork.status === 'COMPLETED' || paperwork.status === 'DELETED') {
                    return
                } else {
                    return <td><span className={'colore-red bold-magenta'}><b>Not allowed</b></span></td>
                }
            })}


        </tr>
    );

    const readersList = readers.map((numero, index) =>
        <tr key={index}>
                <td>{numero.id}</td>
                <td>{numero.name} {numero.surname}</td>
                <td>{numero.email}</td>
                <td>{numero.phone_number}</td>
                <td><b>completed</b></td>
            </tr>
    );

    const filesNotSignedList = filesNotSigned.map((numero, index) =>
        <tr key={index}>
            <td>{numero.name}</td>
            <td><a data-name-file={numero.name} onClick={downloadFileHandle(numero.name)}>click here</a></td>
            <td><b>not signed</b></td>
        </tr>
    );

    const filesSignedList = filesSigned.map((numero, index) =>
        <tr key={index}>
            <td>{numero.name}</td>
            <td><a data-name-file={numero.name} onClick={downloadFileSignedHandle(numero.name)}>click here</a></td>
            <td><b>signed</b></td>
        </tr>
    );

    if ( statusPaperwork ) {

        const lista = paperwork.map((numero, index) =>
        {
            const date = new Date(numero.created_at);
            const timestamp = date.getTime();

            const date2 = new Date(timestamp);
            const dateLocale = date2.toLocaleString('it-IT');

        
            return  <tr key={index}>
                        <td>{numero.id}</td>
                        <td><a href={ "/paperwork/" + numero.id }>{numero.paperwork_name}</a></td>
                        <td>{dateLocale}</td>
                        <td>{departmentName}</td>
                        <td><b>{ (numero.status === 'DELETED') ? 'deleted' : 'completed' }</b></td>
                    </tr>
        });

        return (
            <div>
                <Container>
                    <Col>
                        <div id="wrapper-content" className={["container-content", "operation-details"]}>
                            <div>
                                <h1>Operation details</h1>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Operation name</th>
                                        <th>Creation date</th>
                                        <th>Department</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lista}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>

                <Container>
                    <Col>
                        <div id="wrapper-content" className={"container-content"}>
                            <div>
                                <h3>Signers</h3>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Firstname and lastname</th>
                                        <th>Email</th>
                                        <th>Phone number</th>
                                        <th>Status</th>
                                        <th>Download audit trail</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {signersList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>


                <Container>
                    <Col>
                        <div id="wrapper-content" className={"container-content"}>
                            <div>
                                <h3>Readers</h3>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Firstname and lastname</th>
                                        <th>Email</th>
                                        <th>Phone number</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {readersList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>

                <Container>
                    <Col>
                        <div id="files-list-download" className={"container-content"}>
                            <div>
                                <h3>Files signed</h3>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Download</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filesSignedList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>

                <div id={"container-download-audit-trail"}>
                    <Col>
                        <Container>
                            <form ref={form} id="register-new-signing-process">

                                <Modal show={showChangeFirmatario} onHide={handleGoBackChangeFirmatario}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>+ Change data of signer</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body id={"signer-data"}>Warning! In case of operation with more than 20 signers please contact the tech team before starting the signing.<br/><br/>
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control size="sm" type="text" id={"signer_firstname_change"} name="signer_firstname" placeholder="First name" defaultValue={changeSigner.firstname} onChange={(e) => setDataUpdate({ ...dataUpdate, firstname: e.target.value })} />
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control size="sm" type="text" id={"signer_lastname_change"} name="signer_lastname" placeholder="Last name" defaultValue={changeSigner.lastname} onChange={(e) => setDataUpdate({ ...dataUpdate, lastname: e.target.value })}/>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" id={"signer_email_change"} name="signer_email" defaultValue={changeSigner.email} onChange={(e) => setDataUpdate({ ...dataUpdate, email: e.target.value })} placeholder="name@example.com" />
                                            <p className={colorMessage}>{messageEmailValidation}</p>
                                        </Form.Group>
                                        <Form.Label>Phone number <b>(please pay attention to the area code)</b></Form.Label>

                                        <Form.Control size="sm" type="text" id={"signer_phone_number_change"} name="signer_phone_number" placeholder="Phone number" defaultValue={changeSigner.phone_number} onChange={(e) => setDataUpdate({ ...dataUpdate, phone_number: e.target.value })}/>


                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleGoBackChangeFirmatario}>
                                            Back
                                        </Button>

                                        <Button variant="primary" className={["d-bgcolor","d-bordercolor", "update-signer-button"]} onClick={subimtDataChange(changeSigner)} disabled={disableButtonUpdateDataSigner}>
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </form>
                        </Container>
                    </Col>
                </div>

            </div>

        );
    } else {

        const lista = paperwork.map((numero, index) =>
        {
            const date = new Date(numero.created_at);
            const timestamp = date.getTime();
            const date2 = new Date(timestamp);
            const dateLocale = date2.toLocaleString('it-IT');
        
            return  <tr key={index}>
                        <td>{numero.id}</td>
                        <td><a href={ "/paperwork/" + numero.id }>{numero.paperwork_name}</a></td>
                        <td>{dateLocale}</td>
                        <td>{departmentName}</td>
                        <td><b>on going</b></td>
                    </tr>
        });

        return (
            <div>
                <Container>
                    <Col>
                        <div id="wrapper-content" className={["container-content", "operation-details"]}>
                            <div>
                                <h1>Operation details</h1>

                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Operation name</th>
                                        <th>Creation date</th>
                                        <th>Department</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {lista}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>

                <Container>
                    <Col>
                        <div id="wrapper-content" className={"container-content"}>
                            <div>
                                <h3>Signers</h3>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Firstname and lastname</th>
                                        <th>Email</th>
                                        <th>Phone number</th>
                                        <th>Status</th>

                                        <th>Actions</th>
                                        <th>Reminder</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {signersList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>


                <Container>
                    <Col>
                        <div id="wrapper-content" className={"container-content"}>
                            <div>
                                <h3>Readers</h3>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Firstname and lastname</th>
                                        <th>Email</th>
                                        <th>Phone number</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {readersList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>

                <Container>
                    <Col>
                        <div id="files-list-download" className={"container-content"}>
                            <div>
                                <h3>Files to be signed</h3>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Download</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {filesNotSignedList}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Container>

                <div id={"container-form-update-signer"}>
                    <Col>
                        <Container>
                            <form ref={form} id="register-new-signing-process">

                                <Modal show={showChangeFirmatario} onHide={handleGoBackChangeFirmatario}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>+ Change data of signer</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body id={"signer-data"}>Warning! In case of operation with more than 20 signers please contact the tech team before starting the signing.<br/><br/>
                                        <Form.Label>First name</Form.Label>
                                        <Form.Control size="sm" type="text" id={"signer_firstname_change"} name="signer_firstname" placeholder="First name" defaultValue={changeSigner.firstname} onChange={(e) => setDataUpdate({ ...dataUpdate, firstname: e.target.value })} />
                                        <Form.Label>Last name</Form.Label>
                                        <Form.Control size="sm" type="text" id={"signer_lastname_change"} name="signer_lastname" placeholder="Last name" defaultValue={changeSigner.lastname} onChange={(e) => setDataUpdate({ ...dataUpdate, lastname: e.target.value })}/>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Label>Email address</Form.Label>
                                            <Form.Control type="email" id={"signer_email_change"} name="signer_email" defaultValue={changeSigner.email} onChange={(e) => setDataUpdate({ ...dataUpdate, email: e.target.value })} placeholder="name@example.com" />
                                            <p className={colorMessage}>{messageEmailValidation}</p>
                                        </Form.Group>
                                        <Form.Label>Phone number <b>(please pay attention to the area code)</b></Form.Label>

                                        <Form.Control size="sm" type="text" id={"signer_phone_number_change"} name="signer_phone_number" placeholder="Phone number" defaultValue={changeSigner.phone_number} onChange={(e) => setDataUpdate({ ...dataUpdate, phone_number: e.target.value })}/>
                                        <Form.Control hidden size="sm" type="text" id={"id_signer"} name="id_signer" placeholder="" defaultValue={changeSigner.id_signer} onChange={(e) => setDataUpdate({ ...dataUpdate, id_signer: e.target.value })}/>


                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={handleGoBackChangeFirmatario}>
                                            Back
                                        </Button>

                                        <Button variant="primary" className={["d-bgcolor","d-bordercolor", "update-signer-button"]} onClick={subimtDataChange(changeSigner)} disabled={disableButtonUpdateDataSigner}>
                                            Update
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </form>
                        </Container>
                    </Col>
                </div>

            </div>


        );
    }

}
export default Paperwork;

/**
 *
 Era nella modale
<!--<Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={subimtReminder(changeSigner)}>
    Resend invitation
</Button>--> **/