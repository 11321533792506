import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Axios from 'axios';
import Cookies from "js-cookie";


const SelectDepartments = () => {

    let access_token = Cookies.get('access_token');
    const [dataDeps, setDataDeps] = useState([]);
    const [dataUserDep, setDataUserDep] = useState([]);
    const [responseEp, setResponseEp] = useState(false);
    const [responseUserDep, setResponseUserDep] = useState(false);

    const getDepartments = async () => {

        if ( !responseEp ) {
        
            let endpoint = process.env.REACT_APP_ENPOINT + "departments";
            //fetch(endpoint).then(response => response.json()).then(data => console.log(data));
            const response = await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify(dataDeps),
                headers: {
                    'Authorization' :   'Bearer ' + access_token,
                    'Accept'        :   'application/json',
                    'Content-Type'  :   'application/json',
                }
            });
            const result = await response.json();
            setDataDeps(result);
            setResponseEp(true);
        }
    };
    
    
    
    
    const getUserDepartment = async () => {

        if ( !responseUserDep ) {
        
            let endpoint = process.env.REACT_APP_ENPOINT + "user-department";
            //fetch(endpoint).then(response => response.json()).then(data => console.log(data));
            const responseUserDep = await fetch(endpoint, {
                method: 'POST',
                body: JSON.stringify(dataUserDep),
                headers: {
                    'Authorization' :   'Bearer ' + access_token,
                    'Accept'        :   'application/json',
                    'Content-Type'  :   'application/json',
                }
            });
            const result = await responseUserDep.json();
            setDataUserDep(result);
            setResponseUserDep(true);

            console.log(result);
        }
    };
    getUserDepartment();

    if ( dataUserDep === 'all') {
        getDepartments();
    }

    const lista = dataDeps.map((numero, index) =>{

        //console.log(index);
        //const date = new Date(numero.updated_at);
        //const timestamp = date.getTime();
        //const date2 = new Date(timestamp);
        //const dateLocale = date2.toLocaleString('it-IT');


        return  <Col xs={4}>
                    <Form.Check 
                        type="switch"
                        id={numero.department_name.replace(/\s/g, "")}
                        label={numero.department_name}
                    />
                </Col>
    });

    
    return (        
        <Container className={["margin-bottom-30", "padding-left-30"]}>
            <Row>
                    <Col xs={4}>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="switch-1"
                            label="Check this switch"
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="disabled switch"
                            id="switch-2"
                        />
                    </Col>
                    <Col xs={4}>
                    <Form.Check // prettier-ignore
                            type="switch"
                            label="disabled switch"
                            id="switch-3"
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="switch-4"
                            label="Check this switch"
                        />
                    </Col>
                    <Col xs={4}>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="switch-5"
                            label="Check this switch"
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="disabled switch"
                            id="switch-6"
                        />
                    </Col>
            
            </Row>
        </Container>
        /*<div>
        <select value={selected} onChange={handleChange}>
        {options.map(option => (
            <option
            disabled={option.disabled}
            key={option.value}
            value={option.value}
            >
            {option.text}
            </option>
        ))}
        </select>
    </div>*/
    );
    
}

export default SelectDepartments;