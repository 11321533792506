import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faDatabase, faPenNib } from '@fortawesome/free-solid-svg-icons'
import logo from '../monogramma.png';
import { Navigate, useNavigate, useLocation } from 'react-router-dom';
import Axios from "axios/index";

const Navigation = () => {

    const location = useLocation();
    console.log(location.pathname);
    /**
     * Controlla se l'utente è loggato, se non è loggato
     * viene reindirizzato alla pagina di login
     */
    let access_token = Cookies.get('access_token');
    const [data, setData] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loc, setLoc] = useState(location.pathname);

    let endpoint = process.env.REACT_APP_ENPOINT + "is-user-logged";
    //console.log(access_token);

    let auth = false;

    const logout = e => {
        console.log('aggiungere risultati ricerca, sia per pending che per completed, per id e nome pratica. Al momento funziona solo su email');
        e.preventDefault();

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };

        let endpoint = process.env.REACT_APP_ENPOINT + "logout";
        Axios({
            method: "post",
            url: endpoint,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {},
        }).then(res => {
            console.log(res);
            if ( res.data == 'logout' ) window.location.href = '/login';
        }).catch(e => {
            console.error('Failure', e);
        });
    };




    const isUserLogged = async () => {
        //e.preventDefault();
        const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Authorization' :   'Bearer ' + access_token,
            'Accept'        :   'application/json',
            'Content-Type'  :   'application/json', 
        }
        });
        const result = await response.json();
        console.log(result);

        
        if ( result === 'auth' ) {
            setIsLoggedIn(true);
        } else {
            if ( loc !== '/login') window.location.href = '/login';
        }
        return result;
    }

    useEffect(()=>{
        const MINUTE_MS = 300000;
        const interval = setInterval(() => {
            console.log('Check loging every 5 minute');
            isUserLogged();
        }, MINUTE_MS);
        isUserLogged();
    }, []);


    //https://stackoverflow.com/questions/72343916/await-function-returns-a-promise-and-returns-true-even-if-the-condition-is-false
    //<Nav.Link href="/login" id="home">Home</Nav.Link>
    //<FontAwesomeIcon className={"ciao"} icon={ faPenNib } />
    if ( isLoggedIn ) {
        return (
            <div>
            <Navbar bg="light" variant="light" expand="lg" className={"navbar-sign"}>

                <Navbar.Brand href="/signing-pending">
                    <img
                        src={logo}
                        width="106"
                        height="70"
                        className="d-inline-block align-top"
                        alt="LEXIA Sign"
                    />
                    <h3 className='welcome-message'><b>Welcome</b>,<br/>Admin Lexia</h3>
                </Navbar.Brand>
                

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className={"menu-sign"}>
                    <Nav className="mr-auto">
                        <Nav.Link href="/signing-pending" id="" className={"only-link-menu-sign"}>On going signing</Nav.Link>
                        <Nav.Link href="/signing-completed" id="" className={"only-link-menu-sign"}>Archive</Nav.Link>
                        
                        <Nav.Link href="/new-signing" id="">
                            <Button variant="danger" className={"d-bgcolor"}>+ New signing</Button>{' '}
                        </Nav.Link>

                        <Nav.Link href="/logout" id="" onClick={logout}>
                            <FontAwesomeIcon className={"icons-header"} icon={ faArrowRightFromBracket } />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>

            </Navbar>
            </div>
        );
    } else {
        return (
            <div>
            <Navbar bg="light" variant="light" expand="lg" >
                <Container>
                <Navbar.Brand href="/">
                    <img
                        src={logo}
                        width="106"
                        height="70"
                        className="d-inline-block align-top"
                        alt="LEXIA Sign"
                    />
                </Navbar.Brand>
                </Container>
            </Navbar>
            </div>
        );
    }


}
export default Navigation;

/*
<Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="/login" id="home">Login</Nav.Link>
                    </Nav>
                </Navbar.Collapse>


                <Nav.Link href="/login" id="">
                            <FontAwesomeIcon className={"icons-header"} icon={ faDatabase } />
                        </Nav.Link>
 */