import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { redirect } from "react-router-dom";
import { Navigate } from "react-router-dom";
//import SearchForm from '../components/SearchForm';

function Login() {
    const [data, setData] = useState({});
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [message, setMessage] = useState('Email or password wrong. Please retry or contact admin. Thanks.');
    const [error, setError] = useState(false);

    let endpoint = process.env.REACT_APP_ENPOINT + "login";

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json'
        }
        });
        const result = await response.json();
        
        let access_token = Cookies.set('access_token', result.access_token);
        console.log(access_token);
        console.log(result);

        if( result.access_token != null ) {
            console.log('ciao');
            window.location.href = '/signing-completed';
        }

        if ( result === 'auth' ) {
            setIsLoggedIn(true);
            return redirect("/signing-completed");
            //navigate("");
            //return setIsLoggedIn(true);
        } else {
            setError(true);
        }

        return setIsLoggedIn(false);
        //return redirect("/signing-completed");
    }

    return (
        <Container>
            <Row>
                <Col xs={4}></Col>
                <Col xs={4}>
                    <div id="wrapper-content" className={["container-content", "login-page"]}>
                        <div>
                            <h1>Login LEXIA Sign</h1>
                            <form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email" onChange={e => setData({...data, email: e.target.value})} />
                                </Form.Group>
                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control type="password" placeholder="Password" onChange={e => setData({...data, password: e.target.value})} />
                                </Form.Group>
                                { error ? <Form.Group className={["red-message"]}><Form.Label>{message}</Form.Label></Form.Group> : ''}
                                <Button variant="primary"  className={["d-bgcolor","d-bordercolor"]} onClick={handleSubmit}>
                                    Submit
                                </Button>
                            </form>
                        </div>
                    </div>
                </Col>
                <Col xs={4}></Col>
            </Row>
        </Container>
    );
}

/*const Login = () => {

    const [users, setUsers] = useState([])

    let endpoint = process.env.REACT_APP_ENPOINT + "login";
    console.log(process.env);
    console.log(endpoint.toLowerCase());

    const fetchUserData = () => {
        fetch(endpoint, {method: 'POST', mode: 'cors'}).then(response => {
                return response.json()
            }).then(data => {
                setUsers(data)
            })
    }
    fetchUserData()
  
  return (
    <div>
      Test
    </div>
  );
}*/
export default Login;