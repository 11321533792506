import React from 'react';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import NuovaPraticaForm from '../components/NuovaPraticaForm';

const SigningNew = () => {
    return (
        <Container>
            <Col>
                <div id="wrapper-content" className={"container-content"}>
                    <div>
                        <h1 className='margin-bottom-30'>New Signing</h1>
                        <NuovaPraticaForm/>
                    </div>
                </div>
            </Col>
        </Container>
    );
}
export default SigningNew;