import React, { useState, useEffect, useRef } from 'react';
import { useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import InputGroup from 'react-bootstrap/InputGroup';
import Axios from 'axios';
import Cookies from "js-cookie";
import SelectDepartments from './SelectDepartments';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket, faTrashCan, faPenNib } from '@fortawesome/free-solid-svg-icons'
//import {faArrowRightFromBracket} from "@fortawesome/free-solid-svg-icons/index";

const NuovaPraticaForm = () => {

    const [dataToSend, setDataToSend] = useState([]);
    const { register, handleSubmit } = useForm();
    //const fDData = global.FormData;
    //const fData = new FormData();
    const [files, setFiles] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [department, setDepartment] = useState('');
    const [departmentsList, setDepartmentsList] = useState([]);
    const [signers, setSigners] = useState([]);
    const [readers, setReaders] = useState([]);
    const [message, setMessage] = useState([]);
    const [changeSigner, setChangeSigner] = useState([]);
    const [messageEmailValidation, setMessageEmailValidation] = useState([]);
    const [colorMessage, setColorMessage] = useState('');
    const [isLoading, setLoading] = useState(false);

    const [paperwork, setPaperwork] = useState('');

    const [tmpEmailChangeSigner, setTmpEmailChangeSigner] = useState("");

    const [prefix, setPrefix] = useState('+39');
    const [inputSignerPhone, setInputSignerPhone] = useState('');

    const [validated, setValidated] = useState(false);

    const [emailCheckValidation, setEmailCheckValidation] = useState('');

    const arrChangeSigner = [];
    const prefixList = ['+31', '+33', '+39', '+49'];
    //const changePrefixList = [];
    const [changePrefixList, setChangePrefixList] = useState([]);



    /**
     * Controlla se l'utente è loggato, se non è loggato
     * viene reindirizzato alla pagina di login
     */
    let access_token = Cookies.get('access_token');

    const [data, setData] = useState([]);
    const [responseEp, setResponseEp] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);


    let endpoint = process.env.REACT_APP_ENPOINT + "is-user-logged";
    //console.log('check logged called');
    const isUserLogged = async () => {

        //e.preventDefault();
        const response = await fetch(endpoint, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization' :   'Bearer ' + access_token,
                'Accept'        :   'application/json',
                'Content-Type'  :   'application/json',
            }
        });
        const result = await response.json();

        //result === 'auth' ? navigate('/signing-pending') : null;
        if ( result === 'auth' ) {
            setIsLoggedIn(true);
        }
        return result;
    };

    useEffect(()=>{
        isUserLogged();
    }, []);


    



    /**
     * <Button onClick={handleUpload} variant="light" type="submit" className={["d-bordercolor"]}>
                                        + Carica pdf
                                    </Button>
     */
    // Le tre costanti sotto sono per le modali
    const [showFileInput, setShowFileInput] = useState(false);
    const [showFirmatario, setShowFirmatario] = useState(false);
    const [showChangeFirmatario, setShowChangeFirmatario] = useState(false);
    const [showLettore, setShowLettore] = useState(false);

    let ddd = null;

    // Salva lo stato del file input, se files inseriti valorizzare a true
    const [statusFilesEntered, setStatusFilesEntered] = useState(false);

    const [filesUpload, updateFilesUpload] = useState([]);

    const handleGoBackFirmatario = () => {
        setMessageEmailValidation("");
        setColorMessage('black-message');
        setShowFirmatario(false);
    }

    const handleGoBackChangeFirmatario = () => {
        setMessageEmailValidation("");
        setColorMessage('black-message');
        setShowChangeFirmatario(false);
    }

    const handleAddFirmatario = () => {
        let signer_firstname = document.getElementById('signer_firstname');
        let signer_lastname = document.getElementById('signer_lastname');
        let signer_email = document.getElementById('signer_email');

        let signer_phone_number = document.getElementById('signer_phone_number');




        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //const pattern = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/i;
        const pattern = /^\s+|\s+$/g;

        let validEmail = emailCheckValidation.replace(pattern, "");


        if ( !signer_firstname.value || !signer_lastname.value || !signer_email.value || !signer_phone_number.value || regex.test(emailCheckValidation) === false ) {
            if (!signer_firstname.value) alert('Firstname missing');
            if (!signer_lastname.value) alert('Lastname missing');
            if (!signer_email.value) alert('Email missing');
            if (!signer_phone_number.value) alert('Phone number missing');

            if(regex.test(emailCheckValidation) === false) {
                setMessageEmailValidation("The email entered does not appear to have a valid format. It may block the signing process. Check that there are no blank spaces at the beginning and end.");
                setColorMessage('red-message');
            }

            //console.log("inserire messaggio campi mancanti e errati per il signer")
        } else {
            //             let s = [{
            //                 firstname: signer_firstname.value,
            //                 lastname: signer_lastname.value,
            //                 email: signer_email.value,
            //                 phone_number: signer_phone_number.value
            //             }];

            //             let s = [];
            //             s["firstname"] = signer_firstname.value;
            //             s["lastname"] = signer_lastname.value;
            //             s["email"] = signer_email.value;
            //             s["phone_number"] = signer_phone_number.value;

            //             const s = new Array();
            //             s["firstname"] = signer_firstname.value;
            //             s["lastname"] = signer_lastname.value;
            //             s["email"] = signer_email.value;
            //             s["phone_number"] = signer_phone_number.value;

            
            let s = [{
                firstname: signer_firstname.value.trim(),
                lastname: signer_lastname.value.trim(),
                email: validEmail.trim(),
                phone_number: String(prefix) + String(signer_phone_number.value.trim())
            }];



            signers.forEach( item => {

            });

            const isFound = signers.some( signer => {
                if ( signer[0].email === signer_email.value ) {
                    console.log('aggiungere controlli firmatario');
                    console.log('aggiungere messaggio che il firmatario deve avere mail differente dai lettori e firmatari');
                    //setMessage(a => [...a, '<span class="colore-red">Non puoi aggiungere lo stesso file più volte o file con lo stesso nome: {file.name }</span>']);
                    return true;
                }
                return false;
            });

            setMessageEmailValidation("");
            setColorMessage('black-message');

            if ( !isFound ) setSigners(a => [ ...a, s]);

            setShowFirmatario(false);
            setPrefix('+39');
        }
    }

    const handleRemoveSigner = remove => () => {
        setSigners(prev => prev.filter((el) => el[0].email !== remove ));
        setShowChangeFirmatario(false);
    };


    const handleChangeSigner = change => () => {
        //console.log(Object.entries(change));


        Object.entries(change).map(([key, value]) => {
            //console.log(key);
            //console.log(value);
        });
        arrChangeSigner['firstname'] = change.firstname.trim();
        arrChangeSigner['lastname'] = change.lastname.trim();
        arrChangeSigner['email'] = change.email.trim();
        arrChangeSigner['phone_number'] = change.phone_number.trim();

        console.log(tmpEmailChangeSigner);
        console.log('set change email');
        console.log(change.email);
        setTmpEmailChangeSigner(change.email.trim());

        //ListSelectPrefix(change.phone_number);

        //const arr = Object.keys(change).map((k) => change[k]);

        const arr = Array.from(Object.keys(change), k => change[k]);
        setChangeSigner(arrChangeSigner);

        setShowChangeFirmatario(true);
        //Soto per rimuovere il firmatario da signers
        //setSigners(prev => prev.filter((el) => el[0].email !== change ));

    };

    const handleConfirmChangeSigner = change => () => {


        /*
        Object.entries(change).map(([key, value]) => {
            console.log(key);
            console.log(value);
        });*/

        /*
        arrChangeSigner['firstname'] = change.firstname;
        arrChangeSigner['lastname'] = change.lastname;
        arrChangeSigner['email'] = change.email;
        arrChangeSigner['phone_number'] = change.phone_number;

        const arr = Array.from(Object.keys(change), k => change[k]);
        setChangeSigner(arrChangeSigner);*/


        let signer_firstname = document.getElementById('signer_firstname_change');
        let signer_lastname = document.getElementById('signer_lastname_change');
        let signer_email = document.getElementById('signer_email_change');
        let signer_phone_number = document.getElementById('signer_phone_number_change');

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        const pattern = /^\s+|\s+$/g;
        let validEmail = emailCheckValidation.replace(pattern, "");

        if ( !signer_firstname.value || !signer_lastname.value || !signer_email.value || !signer_phone_number.value || regex.test(emailCheckValidation) === false ) {
            if (!signer_firstname.value) alert('Firstname missing');
            if (!signer_lastname.value) alert('Lastname missing');
            if (!signer_email.value) alert('Email missing');
            if (!signer_phone_number.value) alert('Phone number missing');

            if(regex.test(emailCheckValidation) === false) {
                setMessageEmailValidation("The email entered does not appear to have a valid format. It may block the signing process. Check that there are no blank spaces at the beginning and end.");
                setColorMessage('red-message');
            }

        } else {

            let s = [{
                firstname: signer_firstname.value.trim(),
                lastname: signer_lastname.value.trim(),
                email: signer_email.value.trim(),
                phone_number: String(signer_phone_number.value.trim())
            }];

            // Trasformo l'array in un oggetto
            const objectData = [{
                firstname: change.firstname.trim(),
                lastname: change.lastname.trim(),
                email: change.email.trim(),
                phone_number: String(change.phone_number.trim())
            }];

            const isObject = (object) => {
                return object != null && typeof object === "object";
            };

            const isDeepEqual = (object1, object2) => {

                const objKeys1 = Object.keys(object1);
                const objKeys2 = Object.keys(object2);

                if (objKeys1.length !== objKeys2.length) return false;

                for (var key of objKeys1) {
                    const value1 = object1[key];
                    const value2 = object2[key];

                    const isObjects = isObject(value1) && isObject(value2);

                    if ((isObjects && !isDeepEqual(value1, value2)) ||
                        (!isObjects && value1 !== value2)
                    ) {
                        return false;
                    }
                }
                return true;
            };

            console.log(objectData);

            setSigners((prevArray) =>
                //prevArray.map((item) => (console.log(typeof objectData)))
                //prevArray.map((item) => ( console.log(item[0].email)))
                prevArray.map((item) => (isDeepEqual(item, objectData) ? s : item ))
            );
            setTmpEmailChangeSigner("");
            console.log(s);


            /*const isFound = signers.some( signer => {

                console.log(signer[0].firstname);
                let newArr = [...signer];
                console.log(newArr);
                if ( tmpEmailChangeSigner === signer[0].email ) {

                    console.log(s);
                    console.log("cancella");
                    console.log(tmpEmailChangeSigner);
                    setSigners(prev => prev.filter((el) => el[0].email !== tmpEmailChangeSigner ));
                    setSigners(a => [ ...a, s]);
                    setTmpEmailChangeSigner("");
                    return;

                }

                console.log(tmpEmailChangeSigner);
            });*/

            /*
            Object.entries(signers).map(([key, value]) => {
                if ( value[key].email === tmpEmailChangeSigner ) {
                    setSigners(prev => prev.filter((el) => el[0].email !== tmpEmailChangeSigner ));
                    console.log('uguale');
                    console.log(value);
                    console.log(change);
                    let newArr = [...value];
                    //value[key].email = change;
                    setSigners(a => [ ...a, s]);

                }
            });*/

            /*signers.forEach( item => {
                console.log(item)
            });*/

            /*const isFound = signers.some( signer => {

                console.log(signer);
                if ( tmpEmailChangeSigner === signer[0].email ) {
                    console.log('aggiungere controlli firmatario change');
                    console.log('aggiungere messaggio che il firmatario deve avere mail differente dai lettori e firmatari');
                    //setMessage(a => [...a, '<span class="colore-red">Non puoi aggiungere lo stesso file più volte o file con lo stesso nome: {file.name }</span>']);

                    //setSigners(a => [ ...a, s]);
                    setTmpEmailChangeSigner("");
                    return true;
                }
                return false;
            });*/



            setMessageEmailValidation("");
            setColorMessage('black-message');



            /*
            Object.entries(signers).map(([key, value]) => {
                console.log('chiave valore');
                console.log(key);
                console.log(value[key].email);
                console.log(signers[key].email);

                if ( value[key].email === tmpEmailChangeSigner ) {
                    setSigners(prev => prev.filter((el) => el[0].email !== value[key].email ));
                }
                //delete(signers[key]);
            });*/


            //setTmpEmailChangeSigner("");

            /*
            if ( !isFound ) {
                setSigners(a => [ ...a, s]);
            } else {
                setSigners(prev => prev.filter((el) => el[0].email !== tmpEmailChangeSigner ));
                setSigners(a => [ ...a, s]);
            }*/

            setShowChangeFirmatario(false);
            setPrefix('+39');
        }


        //Soto per rimuovere il firmatario da signers
        //setSigners(prev => prev.filter((el) => el[0].email !== change ));

    };

    function ListSelectPrefix(props) {

        prefixList.map( item => {

            //changePrefixList.push(item);
            if (item === props.prefix) {

                //return <Dropdown.Item eventKey={item}>{item}</Dropdown.Item>;
                setChangePrefixList(current => [...current, '<Dropdown.Item defaultValue eventKey={item}>{item}</Dropdown.Item>']);
            } else {
                setChangePrefixList(current => [...current, '<Dropdown.Item eventKey={item}>{item}</Dropdown.Item>']);
            }
        });
    }

    const handleShowFirmatario = () => setShowFirmatario(true);


    const inputFilesRef = useRef(new Array());
    const form = useRef(null);


    //const dataForm = new FormData(form.current);

    const handleCloseFileInput = () => {

        let inputFile = document.getElementById("formFileMultiple");
        const filesName = [];

        //inputFiles.current.push(inputFile.files);
        //updateFilesUpload(inputFile => [...inputFile, inputFile.length]);

        for ( let i = 0; i < inputFile.files.length; i++){
            if ( document.querySelector('[data-lifilename="' + inputFile.files[i].name + '"]') === null ) {
                console.log(i);
                filesName.push(inputFile.files[i].name);
                let id = "file_" + i;
            }
        }
        setMessage([]);
        setShowFileInput(false);
    };
    const handleShowFileInput = () => setShowFileInput(true);


    const handleGoBackLettore = () => {
        setMessageEmailValidation("");
        setColorMessage('black-message');
        setShowLettore(false);
    }

    const handleAddLettore = () => {
        let reader_firstname = document.getElementById('reader_firstname');
        let reader_lastname = document.getElementById('reader_lastname');
        let reader_email = document.getElementById('reader_email');
        //let reader_phone_number = document.getElementById('reader_phone_number');

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //const pattern = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/i;
        const pattern = /^\s+|\s+$/g;

        let validEmailR = emailCheckValidation.replace(pattern, "");


        if ( !reader_firstname.value || !reader_lastname.value || !reader_email.value || regex.test(emailCheckValidation) === false ) {
            //console.log("inserire messaggio campi mancanti e errati per il reader")
            if (!reader_firstname.value) alert('Firstname missing');
            if (!reader_lastname.value) alert('Lastname missing');
            if (!reader_email.value) alert('Email missing');

            if(regex.test(emailCheckValidation) === false) {
                setMessageEmailValidation("The email entered does not appear to have a valid format. It may block the signing process. Check that there are no blank spaces at the beginning and end.");
                setColorMessage('red-message');
            }

        } else {

            let s = [{
                firstname: reader_firstname.value.trim(),
                lastname: reader_lastname.value.trim(),
                email: validEmailR.trim(),
                //phone_number: reader_phone_number.value
            }];

            signers.forEach(item => {

            });

            const isFound = readers.some( reader => {
                if ( reader[0].email === reader_email.value ) {

                    //setMessage(a => [...a, '<span class="colore-red">Non puoi aggiungere lo stesso file più volte o file con lo stesso nome: {file.name }</span>']);
                    return true;
                }
                return false;
            });

            setMessageEmailValidation("");
            setColorMessage('black-message');

            if ( !isFound ) setReaders(a => [ ...a, s]);

            setShowLettore(false);

        }
    }

    /*const getDepartmentsList = file => () => {

        //e.preventDefault();
        console.log(file);

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        let endpoint = process.env.REACT_APP_ENPOINT + "departments";
        Axios({
            method: "post",
            responseType: 'blob', // importante, altrimenti non funziona, pdf mal formattato
            url: endpoint,
            data: fData,
            headers: {
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {
            console.log('response', res);
        }).catch( e => {
            console.error('Failure', e);
        });
    }*/

    //BEGIN FOR SELECT DEPARTMENT

    let endpointDep = process.env.REACT_APP_ENPOINT + "departments";
    const getDepartmentsList = async () => {

        //e.preventDefault();
        const response = await fetch(endpointDep, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Authorization' :   'Bearer ' + access_token,
                'Accept'        :   'application/json',
                'Content-Type'  :   'application/json',
            }
        });
        const result = await response.json();


        setDepartmentsList(result);
        return result;
    };

    useEffect(()=>{
        getDepartmentsList();
    }, []);
    

    const renderDepartmentsList = departmentsList.map((item, index) => {
        return <option value={item.id}>{item.department_name}</option>
    });

    const handleChangeDepartmentsSelect = event => {

        setDepartment(event.target.value);
    }

    const handleChangeDepartmentsToggle = (checked, value) => {

        if (checked == true) {
            setDepartments(a => [...a, value]);
        } else {
            setDepartments(prev => prev.filter((el) => el !== value));
        }
    }


    const handleShowLettore = () => setShowLettore(true);
    const handleRemoveReader = remove => () => {

        setReaders(prev => prev.filter((el) => el[0].email !== remove ));

    };

    const handleChangePaperwork = input => {

        setPaperwork(input.value);
    }

    const handleChangeEmail = input => {

        setEmailCheckValidation(input.value);

        const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        //const pattern = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/i;
        const pattern = /^\s+|\s+$/g;


        input.value.replace(pattern, "");

        if(regex.test(input.value) === true){
            setMessageEmailValidation("Valid email format.");
            setColorMessage('black-message');
        }
        //setPaperwork(input.value);
    }

    const handleChangeDropDown = (e) => {

        setPrefix(e);
    }

    const handleSignerInputPhone = (input) => {

        setInputSignerPhone(input.value);
    }

    const handleChangeFile = filesUp => {
        let count = 0;
        for ( let i = 0; i < filesUp.length; i++ ){

            const isFound = files.some( file => {
                if ( file.name === filesUp[i].name ) {
                    setMessage(a => [...a, 'Non puoi aggiungere lo stesso file più volte o file con lo stesso nome: ' + file.name ]);
                    count++;
                    return true;
                }
                return false;
            });

            if ( !isFound ) setFiles(a => [ ...a, filesUp[i]]);
        }
        if ( filesUp.length - count > 0 ) setMessage(a => [...a, (filesUp.length - count) + ' file inserted. You can upload further files.']);

        //alert('Warning! Ensure that uploaded documents have at least half a blank page at the bottom for signature certificate printouts. If there are more than 12 signers, leave an entire blank page at the bottom.');
        //setMessage(a => [...a, 'Warning! Ensure that uploaded documents have at least half a blank page at the bottom for signature certificate printouts.']);
    };

    const handleRemoveFile = remove => () => {
        //console.log(message);
        setFiles(prev => prev.filter((el) => el.name !== remove));

    };

    const subimtData = e => {

        e.preventDefault();
        const fData = new FormData();



        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        for ( let n = 0; n < files.length; ++n) {
            fData.append('FileList[]', files[n]);
        }

        /*
        [...signers.keys()].map( k => (
            console.log(k)
        ));


        signers.map(v => (
            console.log(v)
        ));
        */

        if (paperwork.length === 0) {

            alert('You must insert the operation name');
            return;
        }

        if( department.length === 0 ){
            alert('You must insert the department');
            return;
        }
        
        if (files.length === 0) {

            alert('You must insert 1 file at least');
            return;
        }

        if (signers.length === 0) {

            alert('You must insert 1 signer at least');
            return;
        }

        setLoading(true);

        fData.append('Paperwork', paperwork);

        signers.forEach(item => {

            //fData.append('Signers[]', JSON.stringify(item, null, 2));
            fData.append('Signers[]', JSON.stringify(item[0]));
        });

        readers.forEach(item => {
            fData.append('Readers[]', JSON.stringify(item[0]));
        });

        //For toggle: case more department associate
        /*departments.forEach( item => {
            console.log(item);
            fData.append('Departments[]', item);
        });*/
        fData.append('Departments[]', department);


        /*for ( let n = 0; n < signers.length; n++) {
            console.log(signers[n]);
            fData.append('Signers[]', JSON.stringify(signers[n]));
        }*/
        //fData.append('Signers', signers);

        Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };

        let endpoint = process.env.REACT_APP_ENPOINT + "signing-new";
        Axios({
            method: "post",
            url: endpoint,
            data: fData,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + access_token,
                },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {

                if (res.data.hasOwnProperty('error')){
                    alert(res.data.error);
                    setLoading(false);
                } else {
                    setLoading(false);
                    window.location.href = '/signing-pending';    
                }
            }).catch( e => {
                setLoading(true);
                console.error('Failure', e);
            });


        /*Axios.post(endpoint,fData, config)
            .then( res => {
                console.log('response', res);
            }).catch( e => {
                console.error('Failure', e);
        });*/
    }

    return (
        <>
        <div id={"container-form-nuova-pratica"}>
            <form ref={form} id="register-new-signing-process" onSubmit={subimtData}>
                <Container className='margin-bottom-30'>
                    <Row>
                        <Col xs={4}></Col>
                        <Col xs={4}>
                            <Form.Group className="mb-3" controlId="validationCustom01">
                                <Form.Label><b className='fontRedUnderline'>Warning! Operation name will be visible to the signers.</b></Form.Label>
                                <Form.Control required type="text" name={"name_paperwork"} onChange={(e) => handleChangePaperwork(e.target)} placeholder="Insert the operation name" />
                            </Form.Group>
                        </Col>
                        <Col xs={4}></Col>
                    </Row>
                    </Container>

                    <Container className='margin-bottom-30'>
                        <Row>
                            <Col xs={4}></Col>
                            <Col xs={4}>
                            <Form.Select aria-label="Default select example" onChange={handleChangeDepartmentsSelect}>
                                <option>Select department</option>
                                {renderDepartmentsList}
                            </Form.Select>
                            </Col>
                            <Col xs={4}></Col>
                        </Row>                        
                    </Container>                

                    <Container>
                        <Row>
                            <Col xs={4} className={["box-new-sign", "box-file-upload"]}>
                                <div>
                                    <div className={"text-center"}>
                                        <Button onClick={handleShowFileInput} variant="light" className={["d-bordercolor"]}>
                                                + Upload a PDF
                                        </Button>
                                        <ul className={"list-upload-files"}>
                                        {files.map(({ name }) => (
                                            <li className='truncate' data-lifilename={name}
                                                key={ name }>{name}
                                                <Button variant="primary" data-filename={name} id={name} onClick={handleRemoveFile(name)}
                                                        className={["button-remove-file", "d-bgcolor", "d-bordercolor"]}>
                                                    - remove
                                                </Button>
                                            </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={4} className={"box-new-sign"}>
                                <div>
                                    <div className={"text-center"}>
                                        <Button onClick={handleShowFirmatario} variant="light" className={["d-bordercolor"]}>
                                            + Add a signer
                                        </Button>
                                        <ul className={"list-upload-files"}>

                                            {signers.map(( signer, index ) => (
                                                <li className='truncate' data-lifilename={signer[0].email}
                                                    key={ index }>{signer[0].firstname} {signer[0].lastname}

                                                    <Button variant="primary" key={index + "change"} data-filename={index} id={signer[0].email} onClick={handleChangeSigner(signer[0])}
                                                            className={["button-remove-file", "d-bgcolor", "d-bordercolor"]}>
                                                        - change
                                                    </Button>
                                                </li>
                                            ))}

                                        </ul>
                                    </div>

                                </div>
                            </Col>
                            <Col xs={4} className={"box-new-sign"}>
                                <div>
                                    <div className={"text-center"}>
                                        <Button onClick={handleShowLettore} variant="light" className={["d-bordercolor"]}>
                                            + Add a reader
                                        </Button>
                                        <ul className={"list-upload-files"}>

                                            {readers.map(( reader, index ) => (
                                                <li className='truncate' data-lifilename={reader[0].email}
                                                    key={ index }>{reader[0].firstname} {reader[0].lastname}
                                                    <Button variant="primary" key={index} data-filename={index} id={reader[0].email} onClick={handleRemoveReader(reader[0].email)}
                                                            className={["button-remove-file", "d-bgcolor", "d-bordercolor"]}>
                                                        - remove
                                                    </Button>
                                                </li>
                                            ))}

                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <Row>
                            <Col xs={4}></Col>
                            <Col xs={4} className={"text-center"}>
                                <Button variant="primary" onClick={subimtData} id="register" className={["d-bgcolor","d-bordercolor"]} disabled={isLoading}>
                                    {isLoading ? 'Loading…' : 'Start signing'}
                                </Button>
                            </Col>
                            <Col xs={4}></Col>
                        </Row>
                    </Container>





                    <Modal show={showFileInput} onHide={handleCloseFileInput}>
                        <Modal.Header closeButton>
                            <Modal.Title>+ Upload a PDF</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Warning! Ensure that uploaded documents have at least half a blank page at the bottom for signature certificate printouts. If there are more than 12 signers, leave an entire blank page at the bottom.<br/><br/>
                            <Form.Group controlId="formFileMultiple" className="mb-3">
                                <Form.Label>You can upload one or more PDF:</Form.Label>
                                <Form.Control type="file" name="filesUpload" multiple onChange={(e) => handleChangeFile(e.target.files)} />
                            </Form.Group>
                            <ul>
                                {message.map( item =>
                                    <li>
                                        {item}
                                    </li>
                                )}
                            </ul>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={handleCloseFileInput}>
                                Close
                            </Button>
                        </Modal.Footer>
                    </Modal>





                    <Modal show={showFirmatario} onHide={handleGoBackFirmatario}>
                        <Modal.Header closeButton>
                            <Modal.Title>+ Add a signer</Modal.Title>
                        </Modal.Header>
                        <Modal.Body id={"signer-data"}>Warning! In case of operation with more than 20 signers please contact the tech team before starting the signing.<br/><br/>
                            <Form.Label>First name</Form.Label>
                            <Form.Control size="sm" type="text" id={"signer_firstname"} name="signer_firstname" placeholder="First name"/>
                            <Form.Label>Last name</Form.Label>
                            <Form.Control size="sm" type="text" id={"signer_lastname"} name="signer_lastname" placeholder="Last name" />
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" id={"signer_email"} name="signer_email"  onChange={(e) => handleChangeEmail(e.target)} placeholder="name@example.com" />
                                    <p className={colorMessage}>{messageEmailValidation}</p>
                            </Form.Group>
                            <Form.Label>Phone number (selected area code: {prefix})</Form.Label>

                            <InputGroup className="mb-3">
                                <DropdownButton
                                variant="outline-secondary"
                                title="Area code"
                                id="input-group-dropdown-1"
                                onSelect={handleChangeDropDown}
                                >

                                    {prefixList.map( item =>
                                        <Dropdown.Item eventKey={item}>{item}</Dropdown.Item>
                                    )}

                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="">No area code</Dropdown.Item>
                                </DropdownButton>
                                <Form.Control size="sm" type="text" id={"signer_phone_number"} name="signer_phone_number" placeholder="Phone number"/>
                            </InputGroup>
                            

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleGoBackFirmatario}>
                                Back
                            </Button>
                            <Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={handleAddFirmatario}>
                                Add
                            </Button>
                        </Modal.Footer>
                    </Modal>


                <Modal show={showChangeFirmatario} onHide={handleGoBackChangeFirmatario}>
                    <Modal.Header closeButton>
                        <Modal.Title>+ Change data of signer</Modal.Title>
                    </Modal.Header>
                    <Modal.Body id={"signer-data"}>Warning! In case of operation with more than 20 signers please contact the tech team before starting the signing.<br/><br/>
                        <Form.Label>First name</Form.Label>
                        <Form.Control size="sm" type="text" id={"signer_firstname_change"} name="signer_firstname" placeholder="First name" defaultValue={changeSigner.firstname} />
                        <Form.Label>Last name</Form.Label>
                        <Form.Control size="sm" type="text" id={"signer_lastname_change"} name="signer_lastname" placeholder="Last name" defaultValue={changeSigner.lastname}/>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" id={"signer_email_change"} name="signer_email" defaultValue={changeSigner.email} onChange={(e) => handleChangeEmail(e.target)} placeholder="name@example.com" />
                            <p className={colorMessage}>{messageEmailValidation}</p>
                        </Form.Group>
                        <Form.Label>Phone number <b>(please pay attention to the area code)</b></Form.Label>

                        <Form.Control size="sm" type="text" id={"signer_phone_number_change"} name="signer_phone_number" placeholder="Phone number" defaultValue={changeSigner.phone_number}/>


                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleGoBackChangeFirmatario}>
                            Back
                        </Button>
                        <Button variant="primary" key={changeSigner.firstname} data-filename={changeSigner.firstname} id={changeSigner.firstname} onClick={handleRemoveSigner(changeSigner.email)}
                                className={["d-bgcolor","d-bordercolor"]}>
                            Remove
                        </Button>
                        <Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={handleConfirmChangeSigner(changeSigner)}>
                            Change
                        </Button>
                    </Modal.Footer>
                </Modal>





                    <Modal show={showLettore} onHide={handleGoBackLettore}>
                            <Modal.Header closeButton>
                                <Modal.Title>+ Add a reader</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>Reader: a person who oversees the signing process but does not apply his or her own signature<br/><br/>
                                <Form.Label>First name</Form.Label>
                                <Form.Control size="sm" type="text" id={"reader_firstname"} name="reader_firstname" placeholder="First name"/>
                                <Form.Label>Last name</Form.Label>
                                <Form.Control size="sm" type="text" id={"reader_lastname"} name="reader_lastname" placeholder="Last name" />
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email address</Form.Label>
                                    <Form.Control type="email" id={"reader_email"} name="reader_email" onChange={(e) => handleChangeEmail(e.target)} placeholder="name@example.com" />
                                    <p className={colorMessage}>{messageEmailValidation}</p>
                                </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleGoBackLettore}>
                                    Back
                                </Button>
                                <Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={handleAddLettore}>
                                    Add
                                </Button>
                            </Modal.Footer>
                    </Modal>
            </form>
        </div>


    </>

    );
}
//onClick={newSigningProcess} -> per pulsante avvio signing new
//{...register("file")}
export default NuovaPraticaForm;

/**

 <ul className={"list-upload-files"}>
 {signers.map(({ signer }) => (

     <li data-lifilename={signer.email}
         key={ signer.email }>{signer.firstname}
         <Button variant="primary" data-filename={signer.email} id={signer.email} onClick={handleRemoveSigner(signer.email)}
                 className={["button-remove-file", "d-bgcolor", "d-bordercolor"]}>
             - remove
         </Button>
     </li>
 ))}
 </ul>

 ref={(target)=>{
                                    target.value = prefix
                                }}

 onChange={(e)=>{
                                    const input = e.target.value
                                    e.target.value = prefix + input.substr(prefix.length)
                                }}

                                <Form.Label>Numero di telefono (richiesto)</Form.Label>
                            <Form.Control size="sm" type="text" id={"reader_phone_number"} name="reader_phone_number" placeholder="Numero di telefono" />
 */



                            /**
                             * 
                             * 
                             * 
                             * <Container className={["margin-bottom-30", "padding-left-30"]}>
                <Row>
                    <Col xs={4}>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="switch-1"
                            label="Accounting & Tax"
                            value="1"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Capital Markets"
                            id="switch-2"
                            value="2"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Diritto Penale"
                            id="switch-3"
                            value="3"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Energy"
                            id="switch-4"
                            value="4"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                    </Col>
                    <Col xs={4}>
                    <Form.Check // prettier-ignore
                            type="switch"
                            label="Contenzioso"
                            id="switch-5"
                            value="5"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="switch-6"
                            value="6"
                            label="Corporate, M&A and Venture Capital"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Financial Services, Regulatory & FinTech"
                            id="switch-7"
                            value="7"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Foreign Direct Investments & Corporate Services"
                            id="switch-8"
                            value="8"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                    </Col>
                    <Col xs={4}>
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="switch-9"
                            value="9"
                            label="Data & Technology Innovation"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Diritto del lavoro"
                            id="switch-10"
                            value="10"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                        <Form.Check // prettier-ignore
                            type="switch"
                            label="Global Mobility & Immigration"
                            id="switch-11"
                            value="11"
                            onChange={(e) => handleChangeDepartmentsToggle(e.target.checked, e.target.value)}
                        />
                    </Col>
            
                    </Row>
                </Container>
                             */