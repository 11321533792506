import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Table from 'react-bootstrap/Table';
import Axios from "axios/index";
import Cookies from "js-cookie";
import { useLocation } from 'react-router-dom';


const SearchForm = () => {

    const [inputSearch, setInputSearch] = useState('');

    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState(location.pathname);
    const [response, setResponse] = useState([]);
    const [isActive, setIsActive] = useState(false);
    const [isActiveNoResult, setIsActiveNoResult] = useState(false)
    const [stateSign, setStateSign] = useState('');



    const fData = new FormData();

    let access_token = Cookies.get('access_token');




    const submitSearch = e => {
        console.log('aggiungere risultati ricerca, sia per pending che per completed, per id e nome pratica. Al momento funziona solo su email');
        e.preventDefault();

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };

        fData.append('search-sign', inputSearch);
        fData.append('route-frontend', currentRoute);

        if( currentRoute == '/signing-completed') {
            setStateSign('Data completamento');
        }

        if( currentRoute == '/signing-pending') {
            setStateSign('Data creazione');
        }

        //useEffect(() => {

        Axios.defaults.headers = {
            'Content-Type': 'multipart/form-data',
        };

        let endpoint = process.env.REACT_APP_ENPOINT + "search";
        Axios({
            method: "post",
            url: endpoint,
            data: fData,
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {
            //console.log('response', res.data);
            //console.log(res.data);
            console.log(res.data.length);
            if ( res.data.length > 0 ) {
                console.log('attive');
                setIsActive(true);
                setIsActiveNoResult(false);
            } else {
                setIsActiveNoResult(true);
                setIsActive(false);
            }
            setResponse(res.data);
            //console.log(response);
        }).catch( e => {
            console.error('Failure', e);
        });

        //}, []);


        /*Axios.post(endpoint,fData, config)
            .then( res => {
                console.log('response', res);
            }).catch( e => {
                console.error('Failure', e);
        });*/
    };



    console.log('risposta', response);

    const handleInputSearch = input => {
        setInputSearch(input.value);//

    };

    return (
        <div>
            <div id={"container-form"}>
                <Form>
                    <Container>
                    <Row>
                        <Col xs={9}>
                            <Form.Group className="mb-3">
                                <Form.Control type="text" placeholder="Search by operation name, email, signer and department" onChange={(e) => handleInputSearch(e.target)} />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit" onClick={submitSearch} className={["d-bgcolor","d-bordercolor"]}>
                                Submit
                            </Button>
                        </Col>
                    </Row>
                    </Container>
                </Form>
            </div>



                <Col>
                    <div id="results-search" className={isActiveNoResult ? 'is-active' : 'not-active'}>
                        <div>
                            <h3 className={"fontRedUnderline"}>No matches found.</h3>
                        </div>
                    </div>
                    <div id="results-search" className={isActive ? 'is-active' : 'not-active'}>
                        <div>
                            <h3 className={"fontRedUnderline"}>Search reasults: {response.length}</h3>
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Practice name</th>
                                    <th>{stateSign}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {response.map(item => (
                                    <tr>
                                        <td data-lifilename={item.paperwork_name} key={item.id}>{item.id}</td>
                                        <td><a href={ "/paperwork/" + item.id }>{item.paperwork_name}</a></td>
                                        <td>{item.created_at}</td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>

        </div>
    );
}
export default SearchForm;

/*

{response.map(({ name }) => (
                                    <li data-lifilename={name}
                                        key={ name }>{name}
                                        <Button variant="primary" data-filename={name} id={name}
                                                className={["button-remove-file", "d-bgcolor", "d-bordercolor"]}>
                                            - remove
                                        </Button>
                                    </li>
                                ))}

 */