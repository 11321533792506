import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import SigningPending from './pages/SigningPending';
import SigningCompleted from './pages/SigningCompleted';
import SigningNew from './pages/SigningNew';
import Paperwork from './pages/Paperwork';
import Error from './pages/Error';
import Navigation from './components/Navigation';
import './App.css';
import Login from './login/Login';

class App extends Component {

    render() {
        return (
            <BrowserRouter>
                <Navigation />
                <Container fluid="true">
                        <Col>
                            <Routes>
                                <Route path="/" element={<Login/>} exact/>
                                <Route path="/about" element={<About/>}/>
                                <Route path="/contact" element={<Contact/>}/>
                                <Route path="/login" element={<Login/>} exact/>
                                <Route path="/signing-pending" element={<SigningPending/>}/>
                                <Route path="/signing-completed" element={<SigningCompleted/>}/>
                                <Route path="/new-signing" element={<SigningNew/>}/>
                                <Route path="/paperwork/:id" element={<Paperwork/>}/>
                                <Route element={<Error/>}/>
                            </Routes>
                        </Col>
                </Container>
            </BrowserRouter>
        );
    }
}

export default App;