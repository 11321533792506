import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Cookies from 'js-cookie';
import SearchForm from '../components/SearchForm';
import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';
import Axios from "axios/index";

const SigningPending = () => {

    /**
     * Controlla se l'utente è loggato, se non è loggato
     * viene reindirizzato alla pagina di login
     */
    let access_token = Cookies.get('access_token');
    const [data, setData] = useState([]);
    const [responseEp, setResponseEp] = useState(false);
    const [responseDep, setResponseDep] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showRestart, setShowRestart] = useState(false);
    const [isRestartProceedButtonDisabled, setIsRestartProceedButtonDisabled] = useState(false);
    const [idPaperworkRestart, setIdPaperworkRestart] = useState("");
    const [currentPagination, setCurrentPagination] = useState(1);
    //const [rateoPagination, setRateoPagination] = useState(5);
    let rateoPagination = 5;
    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(rateoPagination);
    const navigate = useNavigate();

    const handleRestart = input => () => {

        console.log('cioa');
        //e.preventDefault();
        const fData = new FormData();
        fData.append('paperwork_id', input);
        console.log(fData);


        setIsRestartProceedButtonDisabled(!isRestartProceedButtonDisabled);

        const config = {
            'headers': {
                'Authorization': 'Bearer ' + access_token,
                'Content-Type': 'multipart/form-data'
            }
        };


        Axios.defaults.headers = {
            'Content-Type': 'application/json',
        };

        let endpoint = process.env.REACT_APP_ENPOINT + "restart-signing";
        Axios({
            method: "post",
            url: endpoint,
            data: fData,
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + access_token,
            },
            transformRequest: (data, error) => {
                return fData;
            },
        }).then( res => {

            if (res.data.hasOwnProperty('error')){
                alert(res.data.error);
                setShowRestart(false);
            } else {
                window.location.reload(false);
                setShowRestart(false);
                //window.location.href = '/signing-pending';
            }
        }).catch( e => {
            console.error('Failure', e);
        });
    }

    const handleChangeData = path => () => {
        navigate(path);
    };

    let endpoint = process.env.REACT_APP_ENPOINT + "is-user-logged";

    let auth = false;

    const isUserLogged = async () => {
        //e.preventDefault();
        const response = await fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
            'Authorization' :   'Bearer ' + access_token,
            'Accept'        :   'application/json',
            'Content-Type'  :   'application/json', 
        }
        });
        const result = await response.json();
        console.log(result);
        //console.log(access_token);
        
        //console.log(result);
        //const navigate = useNavigate();
        //result === 'auth' ? navigate('/signing-pending') : null;
        if ( result === 'auth' ) {
            console.log('navigation')
            setIsLoggedIn(true);
        }
        return result;
    };

    useEffect(()=>{
        isUserLogged();
    }, []);


    if ( isLoggedIn ) {

        const getListPending = async () => {

            if ( !responseEp ) {
                console.log('is logged completed signing route');
                let endpoint = process.env.REACT_APP_ENPOINT + "signing-pending";
                //fetch(endpoint).then(response => response.json()).then(data => console.log(data));
                const response = await fetch(endpoint, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Authorization': 'Bearer ' + access_token,
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                    }
                });
                const result = await response.json();
                setData(result);
                setResponseEp(true);
                console.log(result);
            }
        };
        getListPending();
    }

    const handleGoBackRestart = () => {
        setShowRestart(false);
    }

    const handleShowModalRestart = id_paperwork => () => {
        setShowRestart(true);
        setIdPaperworkRestart(id_paperwork);
        console.log(id_paperwork);
    }
    
    const lista = data.map((item, index) =>
    {
        const date = new Date(item.updated_at);
        const timestamp = date.getTime();
        const date2 = new Date(timestamp);
        const dateLocale = date2.toLocaleString('it-IT');

        return  (<tr key={index}>
                    <td>{item.id}</td>
                    <td><a href={ "/paperwork/" + item.id }>{item.paperwork_name}</a></td>
                    <td>{dateLocale}</td>
                    <td>{item.department_name}</td>
                    <td><b>on going</b></td>
                    <td>
                        <Button variant="primary" className={["d-bgcolor","d-bordercolor"]} onClick={handleShowModalRestart(item.id)}>
                        Restart
                        </Button>
                    </td>
                    <td>

                        {item.id >= 203 ?  (
                        <Button variant="primary" className={["d-bgcolor", "d-bordercolor"]}
                                onClick={handleChangeData("/paperwork/" + item.id)}>
                            Update data
                        </Button>
                        ) : (
                            <Button disabled variant="primary" className={["d-bgcolor", "d-bordercolor"]}
                                    onClick={handleChangeData("/paperwork/" + item.id)}>
                                Update data
                            </Button>
                        )}
                    </td>
                </tr>)
    });

    let printPagination = [];
    if ( data.length >= 10 ) {
        printPagination.push();
    }

    const handlePagination = pagination => () => {
        
        console.log(pagination);
        if (pagination === 0 ) {
            console.log('pag');
            setCurrentPagination(pagination + 1);
        } else {
            setCurrentPagination(pagination);
        }

        
        if (pagination > data.length/rateoPagination ) {
            setCurrentPagination(Math.ceil(data.length/rateoPagination));
            setEndIndex(data.length);
            setStartIndex(data.length - rateoPagination);
        }

        if (pagination < 1 ) {
            setCurrentPagination(1);
            setEndIndex(pagination * rateoPagination);
            setStartIndex(0);
        }

        if ( 1 < pagination < data.length/rateoPagination ) {
            setEndIndex(pagination * rateoPagination);
            setStartIndex(pagination * rateoPagination - rateoPagination);
        }
    }

        let items = [];
    //const paginationRender = current => () => {

        let active = 1;
        let maxNumberPagination = Math.floor(data.length / rateoPagination) + data.length % rateoPagination ;
    
        items.push(<Pagination.First onClick={handlePagination(1)}/>);
        if ( currentPagination == 1 ) {
            items.push(<Pagination.Prev disabled onClick={handlePagination(currentPagination - 1)}></Pagination.Prev>);
        } else {
            items.push(<Pagination.Prev onClick={handlePagination(currentPagination - 1)}></Pagination.Prev>);
        }

        items.push(<Pagination.Item active={currentPagination}>{currentPagination}</Pagination.Item>);
        items.push(<Pagination.Ellipsis></Pagination.Ellipsis>);

        for (let number = 1; number <= maxNumberPagination; number++) {    
    
            if ( number - 1 > 0 && (number <= currentPagination + 5 ) && (number > currentPagination + 1 )) {
                items.push(
                    <Pagination.Item key={number} active={number === active} onClick={handlePagination(number)}>
                      {number}
                    </Pagination.Item>,
                  );
            }
    
            if ( number === maxNumberPagination ) {
                
            }
        }

        items.push(<Pagination.Ellipsis></Pagination.Ellipsis>);
        items.push(<Pagination.Item>{maxNumberPagination}</Pagination.Item>);
        if ( Math.ceil(data.length / rateoPagination) === currentPagination ) {
            items.push(<Pagination.Next disabled onClick={handlePagination(currentPagination + 1)}/>);
        } else {
            items.push(<Pagination.Next onClick={handlePagination(currentPagination + 1)}/>);
        }

        items.push(<Pagination.Last onClick={handlePagination(data.length)}/>);

    //}






/**
 * <p>{useState.data}</p>
 */
    return (
        <Container>
            <Col>
                <div id="wrapper-content" className={"container-content"}>
                    <div>
                        <h1>On going signing</h1>
                        <SearchForm/>
                        <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Operation name</th>
                                <th>Creation date</th>
                                <th>Department</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        { (data.length <= rateoPagination) ? lista : lista.slice(startIndex, endIndex)}
                        </tbody>
                        </Table>
                    </div>
                </div>
            </Col>

            <Row>
            <Col>
            <div class="wrapper-content">
                <Pagination disabled>
                    {items}
                </Pagination>
                </div>
            </Col>
            </Row>

            <Modal show={showRestart} onHide={handleGoBackRestart}>
                <Modal.Header closeButton>
                    <Modal.Title>Warning!</Modal.Title>
                </Modal.Header>
                <Modal.Body><strong>By reinitializing the signature process:</strong>
                    <br/>
                    <ul className={"list-circle"}>
                        <li>previous operation will be deleted</li>
                        <li>further costs will be charged on Lexia</li>
                        <li>signers who had already signed the documents will be asked to sign again</li>
                    </ul>
                    Deleting ID:: {idPaperworkRestart}<br/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleGoBackRestart}>
                        Go back
                    </Button>
                    <Button variant="primary" disabled={isRestartProceedButtonDisabled} className={["d-bgcolor","d-bordercolor"]} onClick={handleRestart(idPaperworkRestart)}>
                        Proceed
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </Container>

    );
}
export default SigningPending;


/**
 * 
 * 
 */